export const initialAbility = [
  {
    action: 'read',
    subject: ['Auth', 'Misc'],
  },
]

export const adminAbility = [
  // role 'admin' (1)
  {
    action: 'manage',
    // all resource types.
    // NOTE: subject is same as resource in the route files
    subject: ['all'],
  },
]

export const userAbility = [
  // role 'normal user' (2)
  {
    action: 'manage',
    subject: ['Auth', 'Misc', 'Dashboard', 'Campaign', 'Company'],
  },
]

export const humetricAbility = {
  1: [
    // role 'admin' (1)
    {
      action: 'manage',
      subject: ['all'],
    },
  ],
  2: [
    // role 'clinic manager' (2)
    {
      action: 'manage',
      subject: ['all'],
    },
  ],
  3: [
    // role 'clinician' (3)
    {
      action: 'manage',
      subject: ['Auth', 'Misc', 'Dashboard', 'Profile'],
    },
  ],
}

export const _ = undefined
