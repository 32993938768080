export default [
  {
    path: '/clinicians',
    name: 'view-clinicians',
    component: () => import('@/views/clinicians/index.vue'),
    meta: {
      resource: 'Clinicians',
      action: 'view',
    },
  },
  {
    path: '/clinician/:uuid',
    name: 'view-clinician',
    component: () => import('@/views/clinicians/view.vue'),
    meta: {
      resource: 'Clinician',
      action: 'view',
    },
  },
]
