export default [
  {
    path: '/patients',
    name: 'view-patients',
    component: () => import('@/views/patients/index.vue'),
    meta: {
      resource: 'Patients',
      action: 'view',
    },
  },
  {
    path: '/patients/assigning',
    name: 'assigning-patients',
    component: () => import('@/views/patients/assigning.vue'),
    meta: {
      resource: 'Patients',
      action: 'view',
    },
  },
  {
    path: '/patient/:uuid',
    name: 'view-patient',
    component: () => import('@/views/patients/view.vue'),
    meta: {
      resource: 'Patient',
      action: 'view',
    },
  },
]
