export default [

  /* PROFILE ROUTES */

  { // read
    path: '/profile',
    name: 'view-profile',
    component: () => import('@/views/profile/ViewProfile.vue'),
    meta: {
      resource: 'Profile',
      action: 'read',
    },
  },
  { // update
    path: '/profile/edit',
    name: 'edit-profile',
    component: () => import('@/views/profile/EditProfile.vue'),
    meta: {
      resource: 'Profile',
      action: 'update',
    },
  },

]
