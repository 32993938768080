import apiJwtConfig from './jwtConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...apiJwtConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns) {
    this.axiosIns = axiosIns

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        // set base url
        config.baseURL = this.jwtConfig.baseUrl // eslint-disable-line no-param-reassign

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        /* const { config, response: { status } } = error */
        const { response } = error
        /* const originalRequest = config */

        // if (status === 401) {
        if (response && response.status === 401) {
          console.log('useJwt 401 Unauthorized', response)

          // Clear localstorage
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('packagedUserData')
          localStorage.removeItem('companyData')
          localStorage.removeItem('userData')

          // Redirect to login page
          window.location.href = '/login'

          /* if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest */
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  /* AUTHENTICATION */

  login(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.login}`, ...args)
  }

  register(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.register}`, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.refresh}`, {
      refreshToken: this.getRefreshToken(),
    })
  }

  /* USERS */

  getCurrentUser() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me`)
  }

  updateUser(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/me`, ...args)
  }

  /* CLINICS */

  getCurrentClinic() {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/clinics/me`)
  }

  /* CLINICIANS */
  getClinicians(args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/clinicians/paged`, { params: args })
  }

  getClinician(cUuid) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/clinicians/${cUuid}`)
  }

  addClinician(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.managers}/clinicians`, ...args)
  }

  updateClinician(...args) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/clinicians/${args[0].uuid}`, ...args)
  }

  deleteClinician(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.managers}/clinicians/${uuid}`)
  }

  /* PATIENTS */
  getPatients(args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/patients/paged`, { params: args })
  }

  addPatient(...args) {
    return this.axiosIns.post(`${this.jwtConfig.endpoints.managers}/patients`, ...args)
  }

  deletePatient(uuid) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.managers}/patients/${uuid}`)
  }

  unAssignClinician(patientUuid, clinicianUuid) {
    return this.axiosIns.delete(`${this.jwtConfig.endpoints.managers}/patients/${patientUuid}/clinicians/${clinicianUuid}`)
  }

  assignClinician(patientUuid, clinicianUuid) {
    return this.axiosIns.put(`${this.jwtConfig.endpoints.managers}/patients/${patientUuid}/clinicians/${clinicianUuid}`)
  }

  /* PATIENTS */
  getReports(pUuid, args) {
    return this.axiosIns.get(`${this.jwtConfig.endpoints.managers}/reports/patients/${pUuid}/paged`, { params: args })
  }
}
