export default [

  /* CLINIC ROUTES */

  { // read
    path: '/clinic',
    name: 'view-clinic',
    component: () => import('@/views/clinics/ViewClinic.vue'),
    meta: {
      resource: 'Clinic',
      action: 'read',
    },
  },

]
