import { $themeConfig } from '@themeConfig'

export default {

  // api base url
  baseUrl: $themeConfig.app.apiBaseUrl,

  // api endpoints
  endpoints: {
    login: '/api/users/login',
    register: '/api/register',
    refresh: '/api/refresh-token', // TODO
    logout: '/api/logout',

    // humetric
    users: '/api/users',
    managers: '/api/managers',
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
